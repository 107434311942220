<template>
  <b-overlay :show="formShow" rounded="true" no-fade style="height:100vh!important" class="mb-5">
    <b-card :aria-hidden="formShow ? 'true' : null">
      <b-row>
        <table class="table">
          <tr>
            <th scope="col" class="bg-primary text-white align-middle" style="font-size: 1.2em;">
              EDIT SURVEY
            </th>
            <th scope="col" class="bg-primary text-white d-flex justify-content-end align-middle">
              <b-button variant="success" v-show="$Can('summary_access')" class="animate__animated animate__fadeIn" style="width: 17%; background-color:white;color:#003b49;margin-right:0.5rem" @click="summaryPage()">
                Summary Explanation
                <!-- <feather-icon style="vertical-align: unset;" icon="ColumnsIcon" /> -->
              </b-button>
              <b-button variant="danger" @click="sendMail()" class="animate__animated animate__fadeIn" v-show="$Can('send_email')" style="width: 17%; background-color:white;color:#003b49;margin-right:0.5rem">
                Send Email
                <!-- <feather-icon style="vertical-align: unset;" icon="SendIcon" /> -->
              </b-button>

              <b-button variant="info" class="animate__animated animate__fadeIn" v-show="$Can('download_survey_button')" style="width: 17%; background-color:white;color:#003b49;margin-right:0.5rem" @click="downloadReport()">
                Download Report
                <!-- <feather-icon style="vertical-align: unset;" icon="DownloadIcon" /> -->
              </b-button>
              <b-button type="submit" @click.prevent="formSubmitted" v-if="userDataRole == 'supermanager'" variant="warning" class="animate__animated animate__fadeIn" style="width: 17%; background-color:white;color:#003b49;margin-right:0.5rem">
                Update
              </b-button>
              <b-button
                type="submit"
                @click.prevent="formSubmitted"
                v-else
                v-show="editSurveyData.status == 'A' && (currentUser == userData.name + ' ' + userData.surname || $Can('survey_update'))"
                variant="warning"
                class="animate__animated animate__fadeIn"
                style="width: 17%;  background-color:white;color:#003b49;margin-right:0.5rem"
              >
                Update
              </b-button>

              <b-button ref="completeButton" variant="success" class="float-right animate__animated animate__fadeIn" v-if="userDataRole == 'supermanager'" v-show="editSurveyData.status == 'A'" style="width: 17%; background-color:white;color:#003b49;margin-right:0.1rem;" @click="completeSurvey()">
                Complete
                <!-- <feather-icon icon="CheckIcon" /> -->
              </b-button>
              <b-button ref="completeButton" class="float-right animate__animated animate__fadeIn" variant="success" v-else v-show="$Can('complete_survey') && editSurveyData.status == 'A'" style="width: 17%;  background-color:white;color:#003b49;margin-right:0.1rem;" @click="completeSurvey()">
                Complete
                <!-- <feather-icon icon="CheckIcon" /> -->
              </b-button>
            </th>
          </tr>
        </table>
      </b-row>

      <validation-observer ref="surveyRules">
        <b-form>
          <b-row class="mt-1">
            <!-- First Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="firstName">First Name</label>
                <validation-provider name="firstName" #default="{ errors }" rules="required">
                  <b-form-input id="firstName" type="text" v-model="editSurveyData.firstName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Last Surname -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Last Name" label-for="lastName">
                <validation-provider name="lastName" #default="{ errors }" rules="required">
                  <b-form-input id="lastName" type="text" v-model="editSurveyData.lastName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="email">
                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                  <b-form-input id="email" type="text" v-model="editSurveyData.emailAdress"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- School Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="School Name" label-for="schoolName">
                <validation-provider name="schoolName" #default="{ errors }" rules="required">
                  <b-form-input id="schoolName" type="text" v-model="editSurveyData.schoolName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Class Size -->
            <b-col cols="12" sm="6" md="4" xl="1">
              <b-form-group label="Class Size" label-for="classSize">
                <validation-provider name="classSize" #default="{ errors }" rules="required">
                  <b-form-input id="classSize" type="number" v-model="editSurveyData.classSize"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Grade Level -->
            <b-col cols="12" sm="6" md="4" xl="2">
              <b-form-group label="Grade Level" label-for="title">
                <validation-provider name="title" #default="{ errors }" rules="required">
                  <!-- <b-form-input id="title" type="text" v-model="editSurveyData.gradeLevel.title"></b-form-input> -->
                  <v-select id="gradeLeves" v-model="editSurveyData.gradeLevel" disabled label="title" :reduce="(val) => val" :clearable="false" :options="gradeLevels" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Event -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Questionnaire" label-for="event">
                <validation-provider name="event" #default="{ errors }" rules="required">
                  <v-select id="event" v-model="editSurveyData.event" :options="eventTypes" :reduce="(val) => val" :filterable="true" label="title" :disabled="true" :clearable="false" input-id="event" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Observer -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Observer" label-for="observer">
                <validation-provider name="observer" #default="{ errors }" rules="required">
                  <v-select
                    id="gradeLeves"
                    v-model="editSurveyData.observer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="fullName"
                    :reduce="(val) => val"
                    :clearable="false"
                    :options="observers"
                    @option:selected="selectedObserver(editSurveyData.observer)"
                    :disabled="!$Can('show_survey_observers')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="surveyDate">Survey Date</label>
                <validation-provider name="surveyDate" #default="{ errors }" rules="required">
                  <flat-pickr id="surveyDate" class="form-control" :config="{ dateFormat: 'm-d-Y' }" placeholder="MM-DD-YYYY" v-model="editSurveyData.surveyDate" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- General Comment -->

            <b-col cols="12" xl="12" v-if="$Can('show_comments')">
              <b-form-textarea id="generalComment" :disabled="!$Can('edit_comments')" v-model="editSurveyData.generalComment" placeholder="General internal comments - not displayed on PDF" rows="3" max-rows="6"></b-form-textarea>
            </b-col>

            <b-col cols="12" xl="12" v-else>
              <b-form-textarea v-if="$Can('show_comments')" id="generalComment" disabled v-model="editSurveyData.generalComment" placeholder="General internal comments - not displayed on PDF" rows="3" max-rows="6"></b-form-textarea>
              <b-form-input v-else id="generalComment" disabled v-model="editSurveyData.generalComment" type="password" placeholder="General internal comments - not displayed on PDF" />
            </b-col>

            <b-row class="mt-2">
              <b-col cols="12" xl="12" md="12" style="margin-bottom: 1rem;">
                <b-tabs vertical pills nav-class="nav-left">
                  <b-tab v-for="group in editSurveyData.event.groups" :key="group.id">
                    <template #title>
                      <div class="tab-title">
                        {{ group.groupName }}
                      </div>
                    </template>

                    <b-col cols="12" xl="12" md="12" sm="12">
                      <p style="padding-left: 1rem;">
                        <i> {{ group.groupName }}</i>
                      </p>
                      <b-table hover responsive fixed style=" height: 75vh;" sticky-header="1000px" head-variant="dark" :items="group.questions" class="position-relative" :fields="fields" show-empty empty-text="No questions found">
                        <template #cell(questions)="data">
                          <span>{{ data.item.title }}</span>
                        </template>

                        <template #cell(answer)="data">
                          <validation-provider :name="data.item.title" #default="{ errors }">
                            <!-- status A Answer type -->
                            <v-select
                              v-if="data.item.status === 'A'"
                              :id="data.item.title"
                              :options="answerTypes"
                              :reduce="(val) => val"
                              :filterable="false"
                              :clearable="false"
                              v-model="data.item.commentAnswer"
                              :input-id="data.item.title"
                              @option:selected="selectedGroupValue(data.item)"
                              @input="takeAQuestionAnswer"
                            />
                            <!-- status B Comment type -->
                            <span>
                              <b-form-textarea
                                v-if="data.item.status != 'A'"
                                :id="data.item.title"
                                placeholder="Comment here"
                                rows="2"
                                debounce="500"
                                no-resize
                                v-model="data.item.commentAnswer"
                                @input="takeAQuestionAnswer"
                                @change="selectedGroupValue(data.item)"
                                style="width:100%"
                              ></b-form-textarea>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </span>
                          </validation-provider>
                        </template>
                      </b-table>
                    </b-col>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card :show="formShow" v-if="timeLines.length > 0">
      <app-timeline class="mt-2">
        <app-timeline-item v-for="item in timeLines" :key="item.id" :variant="varyant(item.status)">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ item.title }}</h6>
            <small class="text-muted">{{ dateFormat(item.created_at) }}</small>
          </div>
          <p v-if="item.surveyId != 0" style="cursor: pointer" @click="surveyClick(item.surveyId)">{{ ' Survey Id : #' + item.surveyId }}</p>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <br /><br /><br />

    <template #overlay v-if="download || mailsend">
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
        <p v-if="download" id="cancel-label"><feather-icon icon="DownloadIcon" /> Report creating please wait...</p>
        <p v-if="mailsend" id="cancel-label"><feather-icon icon="DownloadIcon" /> Mail sending please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BIcon, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import axiosIns from '@/libs/axios';
import { t } from '@/@core/libs/i18n/utils';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'animate.css';

import moment from 'moment';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BIcon,
    AppTimeline,
    AppTimelineItem,
    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var editSurveyData = {
      surveyDate: null,
      firstName: null,
      lastName: null,
      emailAdress: null,
      schoolName: null,
      classSize: null,
      gradeLevel: null,
      observer: null,
      status: null,
      currentUser: null,
      event: {
        groups: [],
      },
      generalComment: null,
      questionAnswer: [],
      //groups: [],
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
    ];

    return {
      timeLines: [],
      userDataRole: JSON.parse(localStorage.getItem('userData')).role,
      userData: JSON.parse(localStorage.getItem('userData')),
      currentUser: JSON.parse(localStorage.getItem('userData')),
      editSurveyData: editSurveyData,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      gradeLevels: [],
      observers: [],
      selectedGradeLevels: [],
      email,
      waitCompleteBtn: false,
      dropdownLoading: false,
      formShow: false,
      groups: [],
      download: false,
      mailsend: false,
      ans: null,
      filteredQuestions: [],
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [
        { id: 1, name: 'Elementary Candidates', status: 'Ele' },
        { id: 2, name: 'Middle School Candidates', status: 'Mid' },
      ],
      fields: [
        {
          key: 'questions',
          label: 'questions',
          thStyle: { width: '70%' },
        },
        {
          key: 'answer',
          label: 'answer',
          thStyle: { width: '30%' },
          thClass: 'text-center',
          tdClass: 'text-center ',
        },
      ],
    };
  },
  mounted() {
    this.getSurveyData(this.$route.params.id);
    this.timelineSurveyList(this.$route.params.id);
    this.getObservers();
  },
  methods: {
    takeAQuestionAnswer(ans) {
      this.ans = ans;
    },

    dateFormat(val) {
      return moment(String(val)).format('MM-DD-YYYY HH:mm');
    },

    varicon(val) {
      if (val == 'A') {
        return 'AwardIcon';
      } else if (val == 'B') {
        return 'InfoIcon';
      } else if (val == 'C') {
        return 'ClockIcon';
      } else if (val == 'D') {
        return 'InfoIcon';
      } else if (val == 'E' || val == 'I' || val == 'J') {
        return 'UserIcon';
      } else if (val == 'PA') {
        return 'GridIcon';
      } else if (val == 'P') {
        return 'GridIcon';
      } else if (val == 'G') {
        return 'DollarSignIcon';
      } else if (val == 'H') {
        return 'EyeOffIcon';
      } else {
        return 'success';
      }
    },

    varyant(val) {
      if (val == 'A') {
        return 'info';
      } else if (val == 'B') {
        return 'secondary';
      } else if (val == 'C') {
        return 'warning';
      } else if (val == 'D') {
        return 'danger';
      } else if (val == 'E') {
        return 'success';
      } else if (val == 'I') {
        return 'success';
      } else if (val == 'J') {
        return 'danger';
      } else if (val == 'PA') {
        return 'info';
      } else if (val == 'P') {
        return 'danger';
      } else if (val == 'G') {
        return 'success';
      } else if (val == 'H') {
        return 'danger';
      } else {
        return 'success';
      }
    },

    timelineSurveyList(val) {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        store
          .dispatch('settings/timelineSurveyList', val)
          .then((response) => {
            this.timeLines = response.data;
          })
          .catch((error) => {});
      } else {
        this.formShow = false;
      }
    },

    summaryPage() {
      router.push({
        name: 'survey-settings-summary',
        params: { id: this.$route.params.id },
      });
    },
    selectedGroupValue(val) {
      this.editSurveyData.questionAnswer.forEach((question) => {
        if (question.questionId == Number(val.questionId)) {
          question.commentAnswer = this.ans;
        }
      });
    },
    getGradeLevels() {
      store
        .dispatch('settings/fetchGradeLevels', [])
        .then((res) => {
          this.gradeLevels = [];
          res.data.forEach((gl) => {
            this.gradeLevels.unshift({
              id: gl.id,
              title: gl.title,
              status: gl.status,
            });
          });
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObservers() {
      this.observers = [];
      axiosIns
        .get(`existingObservers`)
        .then((res) => {
          this.observers = res.data.data;
          // this.surveyData.observer = this.userData.fullName;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedObserver(selectedObserver) {
      this.editSurveyData.observer = selectedObserver.fullName;
    },
    completeSurvey() {
      this.formShow = true;
      this.$refs.surveyRules.validate().then((success) => {
        if (success) {
          let completeSurvey = {
            status: 'C',
            id: this.editSurveyData.id,
          };
          this.$swal({
            title: 'Are you sure?',
            text: 'If you approve, you will not be able to update this survey again.',
            icon: 'warning',
            showCancelButton: true,
            // showDenyButton: true,
            confirmButtonText: 'Yes, confirm it!',
            cancelButtonText: 'Cancel',
            // denyButtonText: `Create as new questionnaire`,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger',
              // denyButton: 'btn btn-primary',
            },
            // reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              store
                .dispatch('settings/completeSurvey', completeSurvey)
                .then((res) => {
                  this.toastCompleteMessage('Survey');
                  setTimeout(function() {
                    this.formShow = false;
                    router.push({ name: 'cpat-list' });
                  }, 100);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.formShow = false;
            }
          });
        } else {
          this.formShow = false;
        }
      });
    },
    onHidden() {
      this.$refs.completeButton.focus();
    },

    sendMail() {
      this.formShow = true;
      this.mailsend = true;
      let reportArray = [];
      let downQuestions = [];
      let emptyGroupQuestion = [];

      let groupAnswers = _(this.editSurveyData.questionAnswer)
        .groupBy('questionId')
        .map((question, id, index) => {
          return question[0];
        })
        .value();

      this.editSurveyData.event.groups.forEach((element, index) => {
        //filter and group by all questions
        downQuestions = _.filter(groupAnswers, {
          groupId: element.id,
          // status: 'A',
        });

        //order comment answer
        downQuestions = _.orderBy(downQuestions, ['status'], ['asc']);

        //Questions count / for domain score
        let questionsCount = _.filter(groupAnswers, {
          groupId: element.id,
          status: 'A',
        });

        questionsCount = _.filter(questionsCount, function(o) {
          return o.commentAnswer != 'Not Observed';
        });

        let count = 0;

        if (downQuestions.length > 0) {
          downQuestions.forEach((question) => {
            this.editSurveyData.questionAnswer.forEach((answer) => {
              if (question.questionId === answer.questionId) {
                if (question.status == 'A') {
                  question.itemScore = answer.commentAnswer != 'Not Observed' ? answer.commentAnswer : 0;
                  downQuestions[0].domainScore = ((count += question.itemScore != 'Not Observed' ? question.itemScore : 0) / questionsCount.length).toFixed(2);
                }
              }
            });
          });
        }

        if (element.questions.length == 0) {
          emptyGroupQuestion.push(index);
        }

        reportArray.push(downQuestions);
      });

      // if (reportArray.length > 0) {
      //   reportArray = _.reject(reportArray, _.isEmpty);
      // }

      if (reportArray.length > 0) {
        reportArray = _.reject(reportArray, _.isEmpty);
      }
      if (emptyGroupQuestion.length > 0) {
        for (var i = emptyGroupQuestion.length - 1; i >= 0; i--) this.editSurveyData.event.groups.splice(emptyGroupQuestion[i], 1);
      }

      store
        .dispatch('settings/mailSend', {
          data: reportArray,
          groups: this.editSurveyData.event.groups,
          info: {
            id: this.editSurveyData.id,
            eventName: this.editSurveyData.event.title,
            surveyDate: this.editSurveyData.surveyDate,
            firstName: this.editSurveyData.firstName,
            lastName: this.editSurveyData.lastName,
            emailAdress: this.editSurveyData.emailAdress,
            schoolName: this.editSurveyData.schoolName,
            observer: this.editSurveyData.observer,
            gradeLevel: this.editSurveyData.gradeLevel,
            classSize: this.editSurveyData.classSize,
            generalComment: this.editSurveyData.generalComment,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.mailsend = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is sent!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.formShow = false;
          } else {
            this.formShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.toastDownloadErrorMessage('Download');
          this.formShow = false;
        });
    },
    downloadReport() {
      this.formShow = true;
      this.download = true;
      let reportArray = [];
      let downQuestions = [];
      let emptyGroupQuestion = [];

      let groupAnswers = _(this.editSurveyData.questionAnswer)
        .groupBy('questionId')
        .map((question, id, index) => {
          return question[0];
        })
        .value();

      this.editSurveyData.event.groups.forEach((element, index) => {
        //filter and group by all questions
        downQuestions = _.filter(groupAnswers, {
          groupId: element.id,
          // status: 'A',
        });

        //order comment answer
        downQuestions = _.orderBy(downQuestions, ['status'], ['asc']);

        //Questions count / for domain score
        let questionsCount = _.filter(groupAnswers, {
          groupId: element.id,
          status: 'A',
        });

        questionsCount = _.filter(questionsCount, function(o) {
          return o.commentAnswer != 'Not Observed';
        });

        let count = 0;

        if (downQuestions.length > 0) {
          downQuestions.forEach((question) => {
            this.editSurveyData.questionAnswer.forEach((answer) => {
              if (question.questionId === answer.questionId) {
                if (question.status == 'A') {
                  question.itemScore = answer.commentAnswer != 'Not Observed' ? answer.commentAnswer : 0;
                  downQuestions[0].domainScore = ((count += question.itemScore != 'Not Observed' ? question.itemScore : 0) / questionsCount.length).toFixed(2);
                }
              }
            });
          });
        }

        if (element.questions.length == 0) {
          emptyGroupQuestion.push(index);
        }

        reportArray.push(downQuestions);
      });

      // if (reportArray.length > 0) {
      //   reportArray = _.reject(reportArray, _.isEmpty);
      // }

      if (reportArray.length > 0) {
        reportArray = _.reject(reportArray, _.isEmpty);
      }
      if (emptyGroupQuestion.length > 0) {
        for (var i = emptyGroupQuestion.length - 1; i >= 0; i--) this.editSurveyData.event.groups.splice(emptyGroupQuestion[i], 1);
      }

      store
        .dispatch('settings/cpatSinglePdf', {
          data: reportArray,
          groups: this.editSurveyData.event.groups,
          info: {
            id: this.editSurveyData.id,
            eventName: this.editSurveyData.event.title,
            surveyDate: this.editSurveyData.surveyDate,
            firstName: this.editSurveyData.firstName,
            lastName: this.editSurveyData.lastName,
            emailAdress: this.editSurveyData.emailAdress,
            schoolName: this.editSurveyData.schoolName,
            observer: this.editSurveyData.observer,
            gradeLevel: this.editSurveyData.gradeLevel,
            classSize: this.editSurveyData.classSize,
            generalComment: this.editSurveyData.generalComment,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Growth Report ${this.editSurveyData.surveyDate} - ${this.editSurveyData.firstName + ' ' + this.editSurveyData.lastName}.pdf`);
            document.body.appendChild(link);
            link.click();
            this.download = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.formShow = false;
          } else {
            this.formShow = false;
          }
        })
        .catch((error) => {
          this.toastDownloadErrorMessage('Download');
          this.formShow = false;
        });
    },

    getSurveyData(id) {
      this.formShow = true;
      store
        .dispatch('settings/fetchSurvey', id)
        .then((res) => {
          if (res.status === 200) {
            this.editSurveyData = {
              id: res.data.id ? res.data.id : 0,
              firstName: res.data.firstName ? res.data.firstName : '',
              lastName: res.data.lastName ? res.data.lastName : '',
              emailAdress: res.data.emailAdress ? res.data.emailAdress : '',
              schoolName: res.data.schoolName ? res.data.schoolName : '',
              classSize: res.data.classSize || res.data.classSize === 0 ? res.data.classSize : 0,
              gradeLevel: res.data.gradeLevel ? res.data.gradeLevel : '',
              observer: res.data.observer ? res.data.observer : '',
              event: res.data.event ? res.data.event : '',
              generalComment: res.data.generalComment ? res.data.generalComment : '',
              questionAnswer: res.data.questionAnswer ? _.values(res.data.questionAnswer) : [],
              //groups: res.data.event.groups ? res.data.event.groups : [],
              surveyDate: res.data.surveyDate ? res.data.surveyDate : null,
              status: res.data.status ? res.data.status : 'A',
            };

            //sort question type comment will be for last
            this.editSurveyData.event.groups.forEach((group) => {
              group.questions = _.orderBy(group.questions, ['status'], ['asc']);
            });
            this.currentUser = this.editSurveyData.observer;

            this.getGradeLevels();
          }
        })
        .catch((error) => {
          this.toastErrorMessage('Survey');
          console.log(error);
        });
    },
    formSubmitted() {
      this.formShow = true;
      this.$refs.surveyRules.validate().then((success) => {
        if (success) {
          this.editSurveyData.questionAnswer.forEach((questionAnswer) => {
            this.editSurveyData.event.groups.forEach((group) => {
              group.questions.forEach((question) => {
                if (questionAnswer.questionId == question.id) {
                  questionAnswer.commentAnswer = question.commentAnswer;
                }
              });
            });
          });

          store
            .dispatch('settings/editSurvey', this.editSurveyData)
            .then((res) => {
              this.toastMessage('Survey');
              setTimeout(function() {
                this.formShow = false;
                router.push({ name: 'cpat-list' });
              }, 100);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.formShow = false;
        }
      });
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Edit Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    toastCompleteMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Complete Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    toastErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: '× ' + val + ' error!',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },
    toastDownloadErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: '× ' + val + ' Data not found!',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.left-align .nav-tabs .nav-link {
  justify-content: left !important;
}
.animate__animated.animate__fadeIn {
  --animate-delay: 2s;
}
</style>

<style scoped>
.tab-title {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.925rem;
}
</style>
