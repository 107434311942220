<template>
  <div>
    <!-- <cpat-create-page></cpat-create-page> -->
    <cpat-edit></cpat-edit>
  </div>
</template>

<script>
import CpatCreatePage from './CpatCreatePage.vue';
import CpatEdit from '../../settings/Surveys/EditSurvey.vue';

export default {
  components: {
    CpatCreatePage,
    CpatEdit
  },

  data() {
    return {};
  },
};
</script>
